import {
  PrescriptionResponse,
  SubscriptionControllerService,
  SubscriptionDetailsResponse,
  TaskResponse
} from "@9amhealth/openapi";
import {
  AutoForm,
  AutoFormDataField,
  AutoFormDataFieldDate,
  Button,
  DataFieldGroup,
  pickerCurrentTime,
  useAutoFormControls
} from "@9amhealth/shared";
import styled from "@emotion/styled";
import type { FC } from "react";
import { default as React, useEffect, useMemo } from "react";
import { IconLockInput } from "src/constants/icons";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { validateDateGLP1 } from "src/lib/zod/validateDate";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import TaskManagementBloc, {
  TaskKey
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { tracker, useBloc } from "src/state/state";
import PenImg from "src/ui/assets/images/iso-meds-pen.webp";
import { AppQueryPopupsController } from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import DateTimePicker from "src/ui/components/DateTimePicker/DateTimePicker";
import InDialog from "src/ui/components/InDialog/InDialog";
import {
  CenterEl,
  ErrorPageLayout
} from "src/ui/components/SentryBoundary/ErrorPage";
import { TaskConfigAdditionalData } from "src/ui/components/TaskList/TaskList";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { z } from "zod";
import { CalendarDate } from "@internationalized/date";

//#region Styled components
const Wrapper = styled.div`
  padding: 0 0.6rem;
  text-align: center;
`;

const Title = styled.h1``;

const Subtitle = styled.p`
  color: var(--color-charcoal-80);
  margin: 1rem 0 2rem;
`;

const StyledImg = styled.img`
  margin: 1.5rem 0;
  width: 5rem;
  aspect-ratio: 1;
  background-color: var(--color-cream-darker);
  border-radius: 50%;
`;

const StyledErrorPageLayout = styled(ErrorPageLayout)`
  height: 100%;
`;
//#endregion

type TaskAdditionalData = {
  medicationName?: string;
  medicationNdc?: string;
  medicationStartDate?: string;
  medicationDoseForm?: string;
};

const GLP1StartDateDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const [showPicker, setShowPicker] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [{ tasks }, { loadProgramTasks }] = useBloc(TaskManagementBloc);

  const initialApplicationDateTask = useMemo(
    () =>
      tasks.find(
        (task) =>
          task.slug === TaskKey.INITIAL_APPLICATION_DATE &&
          task.status === TaskResponse.status.AVAILABLE
      ),
    [tasks]
  );

  useEffect(() => {
    if (!initialApplicationDateTask) {
      setLoading(true);
      void loadProgramTasks(KnownProgram.CARE).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const taskAdditionalData = useMemo<TaskAdditionalData | undefined>(() => {
    if (!initialApplicationDateTask) {
      return undefined;
    }

    const typedAdditionalData =
      initialApplicationDateTask.additionalData as TaskConfigAdditionalData;

    return {
      medicationName: typedAdditionalData.medication_display_name,
      medicationNdc: typedAdditionalData.medication_ndc,
      medicationStartDate: typedAdditionalData.medication_start_date,
      medicationDoseForm: typedAdditionalData.dose_form
    };
  }, [initialApplicationDateTask]);

  //#region Schema and types
  const GLP1StartDateSchema = useMemo(
    () =>
      z.object({
        medication: z.string(),
        date: validateDateGLP1(taskAdditionalData?.medicationStartDate)
      }),
    [taskAdditionalData?.medicationStartDate]
  );

  type FormValues = z.infer<typeof GLP1StartDateSchema>;
  //#endregion

  //#region Form initial values and controls
  const initialFormValues = useMemo<FormValues>(() => {
    return {
      medication: taskAdditionalData?.medicationName ?? "",
      date: pickerCurrentTime()
    };
  }, [taskAdditionalData?.medicationName]);

  const autoFormControls = useAutoFormControls({
    schema: GLP1StartDateSchema,
    initialValue: initialFormValues,
    validateOnChange: true,
    onSubmit: (data) => void handleSubmit(data)
  });
  //#endregion

  //#region Event handlers
  const navigate = useGoToOrBack();
  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const [, { getFullSubscription }] = useBloc(SubscriptionCubit);
  const subscription = useMemo(
    () => getFullSubscription([SubscriptionDetailsResponse.status.ACTIVE]),
    []
  );

  const handleSubmit = async (formValues: FormValues): Promise<void> => {
    try {
      BlockingLoadingOverlayController.startLoading({ bg: "transparent" });

      if (!subscription) {
        reportErrorSentry("No active subscription found");
        throw new Error(translate("error_generic"));
      }

      const purchaseItem = subscription.purchaseItems.find(
        (item) =>
          item.metadata["medication.ndc"] === taskAdditionalData?.medicationNdc
      );

      if (!purchaseItem) {
        reportErrorSentry("No purchase item with given ndc found");
        throw new Error(translate("error_generic"));
      }

      const date = new CalendarDate(
        formValues.date.year,
        formValues.date.month,
        formValues.date.day
      ).toString();

      await SubscriptionControllerService.updateSubscriptionItemByUser(
        subscription.id,
        purchaseItem.id,
        { metadata: { "medication.initial-application-date": date } }
      );

      tracker.track(TrackEvent.GLP1StartDateForm, {
        type: TrackType.complete
      });

      BlockingLoadingOverlayController.loadingSuccess();
      onClose();
    } catch (error) {
      reportErrorSentry(error);

      BlockingLoadingOverlayController.loadingError({
        title: translate("error_generic")
      });
    }
  };

  const handleFocusChange = () => {
    const isCoarse = matchMedia("(pointer:coarse)").matches;

    if (!isCoarse) {
      return;
    }

    setShowPicker(isCoarse);
  };
  //#endregion

  const labelContext =
    taskAdditionalData?.medicationDoseForm?.toUpperCase() ===
      PrescriptionResponse.doseForm.MILLILITER ||
    taskAdditionalData?.medicationDoseForm?.toUpperCase() ===
      PrescriptionResponse.doseForm.PEN_NEEDLE
      ? "injection"
      : "application";

  return (
    <InDialog
      title={translate("treatmentStart")}
      onClose={onClose}
      returnUrl={returnUrl}
    >
      <Track event={TrackEvent.GLP1StartDateForm} type={TrackType.open} />

      {!loading && !taskAdditionalData ? (
        <StyledErrorPageLayout>
          <CenterEl>
            <nine-heading>
              <nine-spacer s="xs"></nine-spacer>
              <h4 className="m0 color-c-80">
                <Translate msg="error.generic.short" />{" "}
              </h4>
              <nine-spacer s="md"></nine-spacer>
              <p className="m0 color-c-80">
                <Translate msg="help.contactUsGeneric" />
                <nine-spacer s="xs"></nine-spacer>
                <Translate msg="help.thankYouMessage" />
              </p>
            </nine-heading>
            <nine-spacer s="lg"></nine-spacer>
            <nine-button
              onClick={(): void => {
                navigate("/app/chat");
              }}
            >
              <Translate msg="chatWithCareTeam" />
            </nine-button>
          </CenterEl>
        </StyledErrorPageLayout>
      ) : (
        <>
          <DateTimePicker
            show={showPicker}
            onFinish={() => {
              setShowPicker(false);
            }}
            onChange={(dateTime) => {
              autoFormControls.setValue("date", dateTime);
            }}
            initialDate={autoFormControls.getValues().date}
            title={translate("date")}
            granularity="day"
          />

          <Wrapper>
            <StyledImg
              src={PenImg}
              alt={translate("image.alt.glp1InjectionPen")}
            />

            <Title className="as-h4">
              <Translate msg="glp1StartDateDialog.title" />
            </Title>
            <Subtitle>
              <Translate msg="glp1StartDateDialog.subtitle" />
            </Subtitle>

            <AsyncContent check={[taskAdditionalData]} height="12rem">
              <AutoForm {...autoFormControls.props}>
                <DataFieldGroup>
                  <AutoFormDataField
                    name="medication"
                    label={translate(
                      "glp1StartDateDialog.form.label.medication"
                    )}
                    affix={IconLockInput()}
                    isReadOnly
                  />

                  <AutoFormDataFieldDate
                    onFocus={handleFocusChange}
                    maxValue={pickerCurrentTime()}
                    label={translate(
                      "glp1StartDateDialog.form.label.startDate",
                      {
                        context: labelContext
                      }
                    )}
                    name="date"
                    granularity="day"
                  />
                </DataFieldGroup>

                <nine-spacer s="lg" />

                <Button type="submit" style={{ margin: "auto" }}>
                  <Translate msg="confirm" />
                </Button>
              </AutoForm>
            </AsyncContent>
          </Wrapper>
        </>
      )}
    </InDialog>
  );
};

export default GLP1StartDateDialog;
